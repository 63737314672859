$padding-section: 3rem;
$color-white: #fff;
$color-yellow: #fcd151;
$gap: 3rem;

.how-we-work {
  padding: $padding-section 0;

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: $gap;

    > *:nth-child(1) {
      grid-row: 1;
      grid-column: 1;
    }

    > *:nth-child(2) {
      grid-row: 1;
      grid-column: 2;
    }

    > *:nth-child(3) {
      grid-row: 2;
      grid-column: 1;
    }

    > *:nth-child(4) {
      grid-row: 2;
      grid-column: 2;
    }
  }

  &__link {
    display: flex;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-decoration: none;
    height: 300px;

    &-items {
      margin: 0 0 40px 20px;
    }

    &-title {
      color: $color-white;
      font-weight: 500;
      font-size: 3rem;
      padding-bottom: 10px;
      max-width: 260px;
      line-height: 40px;
    }
  }

  &__item {
    span {
      display: flex;
      align-items: flex-end;
      text-decoration: underline;
      color: $color-yellow;
    }
  }


  @media (max-width: 980px) {
    &__link-title {
      font-size: 2.5rem;
      padding-bottom: 10px;
    }
  }

  @media (max-width: 760px) {
    &__link-title {
      font-size: 2rem;
      padding-bottom: 10px;
      max-width: 220px;
      line-height: 40px;
    }
  }

  @media (max-width: 680px) {
    &__link-title {
      font-size: 1.75rem;
      line-height: 30px;
      max-width: 150px;
      padding-bottom: 5px;
    }

    &__link-items {
      margin: 0 0 20px 20px;
    }

    &__link {
      height: 240px;
    }
  }

  @media (max-width: 550px) {
    &__link-items {
      margin: 0 0 10px 10px;
    }

    &__link-title {
      font-size: 1.5rem;
      max-width: 113px;
    }

    &__item span {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 450px) {
    &__link {
      height: 150px;
    }

    &__link-title {
      font-size: 1rem;
    }

    &__item span {
      font-size: 0.7rem;
    }
  }
}
