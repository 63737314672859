input,
.select,
textarea {
  display: inline-block;
  border-radius: 0.25rem;
  border: 2px solid $primary;
  transition: 0.3s ease background-color, 0.3s ease border-color;

  color: inherit;
}

input,
textarea {
  padding: 0.625rem 1.25rem;
}

input:active,
input:focus-visible,
input:focus,
textarea:active,
textarea:focus-visible,
textarea:focus,
.select:active,
.select:focus-visible {
  outline: 2px solid #e1817c;
  outline-offset: 2px;
}

input[type="checkbox"] {
  appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  padding: 0;
  font: inherit;
  color: currentColor;
  cursor: pointer;
  width: 21px;
  height: 21px;
  border: 1px solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  flex: none;
  display: inline-grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 15px;
  height: 15px;
  clip-path: circle(50%);
  transform: scale(0);
  transform-origin: center;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em $primary;
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
}