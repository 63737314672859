$primary: #d42920;
$primary-300: #e1817c;
$primary-200: #e1a4a1;
$dark: #01232d;
$footer: #282828;
$dark-grey: #818181;
$grey: #b2b4c4;
$light-grey: #f4f4f4;

%input {
  width: 100%;
  border: 1px solid #9C9C9C;
  border-radius: 5px;
  padding: 15px;
  font-weight: bold;


  &::placeholder {
    font-size: 16px;
    color: #9C9C9C;
    font-weight: normal;
  }

  &:focus {
    border-color: #000;
    outline: none;
  }
}

%btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 0 47px;
  background: #1B85FF;
  border: none;
  border-radius: 54px;
  color: #fff;
  font-weight: 800;
  cursor: pointer;
  transition: all .3s ease;

  &:disabled {
    background: #CBE0F7;
    color: #9098A1;
    cursor: not-allowed;
  }

  &:not(:disabled):not(&--disabled):hover {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media (max-width:700px) {
    padding: 0 41px;
    height: 40px;
  }
}

%btn--default {
  background: #64ACFF;
  color: #CBE0F7;
}

%btn--loading {
  width: 126px;
}