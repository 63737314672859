.faq {
  padding: 50px 0;

  &__title {
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    padding-bottom: 68px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__item {
    cursor: pointer
  }

  &__item-title {
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid #000;
    border-radius: 23px;
    width: 100%;
    height: 60px;
    margin: 0;
    padding: 0 40px 0 20px;

    &::after {
      content: '+';
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 20pt;
      transition: all .5s ease;
    }

    &.opened::after {
      transform: rotate(45deg);
    }
  }

  &__item-text {
    padding: 0;
    overflow: hidden;
    line-height: 22px;
    width: 100%;

    p {
      padding: 20px;
    }
  }

}

@media (max-width:760px) {
  .faq {
    padding: 30px 0;

    &__title {
      padding-bottom: 30px
    }
  }

}