.advantages {
  &__items {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
  }

  &__item {
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__item-desc {
    user-select: none;
    display: flex;
    align-items: center;
    text-align: center;
    position: absolute;
    padding: 1.25rem;
    border-radius: 10px;
    opacity: 0;
    transition: all .4s ease;
    top: 0;
    bottom: 0;
    left: 0;
    color: #fff;
    background-color: #2c2c2c;

    &--active {
      opacity: .9;
    }
  }

  &__video {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    background-image: url(../../assets/images/advantages/videobg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    padding: 1.5rem 0;

    img {
      border-radius: 10px
    }
  }
}

.video {
  &__text {
    padding: 2rem 0;
    text-align: center;
  }

  &__title {
    font-size: 3rem;
    padding: 1rem 0;
  }

  &__subtitle {
    font-size: 1.5rem;
  }
}


@media (max-width: 660px) {
  .advantages {
    &__items {
      grid-template-columns: repeat(2, 1fr);
    }

    &__video a {
      width: 500px
    }
  }

  .video {
    &__title {
      font-size: 2.5rem;
      padding: .8rem 0
    }

    &__subtitle {
      font-size: 1.25rem
    }
  }
}

@media (max-width:540px) {
  .video {
    &__title {
      font-size: 2rem;
      padding: .5rem 0
    }

    &__subtitle {
      font-size: 1rem
    }
  }

  .advantages__video a {
    width: 400px
  }
}

@media (max-width:420px) {
  .video {
    &__title {
      font-size: 1.5rem;
      padding: 0;
    }
    &__subtitle {
      font-size: .75rem;
    }
  }

  .advantages {
    &__item-desc {
      font-size: .7rem;
    }
    &__video a {
      width: 300px
    }
  }
}