@import '../../assets/styles/vars';

.header {
  position: unset;
  background-color: #10141F;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 1rem;


  &--main {
    position: absolute;
    background-color: transparent;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__nav-list {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 0 3rem;
  }

  &__nav-item {
    list-style: none;
  }

  &__nav-link {
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
    transition: all .3s ease;

    &:hover {
      color: $primary;
    }
  }

  &__social {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1rem;
  }

  &__social-list {
    box-sizing: border-box;
    padding: 12px;
    top: 0;
    transition: all .5s ease;
    width: 200px;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.48);
    background: #fff;
    border-radius: 4px;


    &--main {
      box-shadow: 1px 1px 2.3px #FFFFFF;
      background-color: transparent;
    }
  }

  &__social-btn {
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    font-size: 1.2rem;
    font-weight: 700;
    justify-content: space-around;
    align-items: center;
    width: 200px;
    height: 35px;
    color: black;
    border-radius: 4px;
    cursor: pointer;
    z-index: 2;
    border: 0.50px solid #fff;

    &--main {
      background-color: transparent;
      color: #fff;
      border: #EDEEF6 .5px solid;
      font-size: 1.1rem;
    }

    svg {
      transition: all .5s ease;
    }
  }

  &__social-link {
    text-decoration: none;
    color: black;
    font-size: 1.25rem;
    transition: 0.3s all ease;
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;

    i {
      font-size: 1.5rem;
    }

    &--main {
      color: #fff;
    }

    &:hover {
      color: $primary;
    }
  }

  &__social-text {
    font-size: 1rem;
    font-weight: 200;
    color: black;

    &--main {
      color: #fff;
    }
  }

  &__social-icon {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
}

.fadeIn {
  opacity: 1;
  top: 90px;
  visibility: visible;
}

.fadeOut {
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.rotate {
  transform: rotate(180deg);
}

.burger-btn {
  display: none;
}

@media (max-width:1180px) {

  .header {
    &__nav-list {
      padding: 0 .5rem;
    }
    &__nav-link {
      font-size: 1.25rem;
    }
  }

  .social-btn {
    font-size: 1rem
  }
}

@media (max-width:980px) {
  .burger-btn {
    cursor: pointer;
    display: block;
    width: 30px
  }

  .header__nav {
    display: none
  }
}

@media (max-width:480px) {
  .header {
    padding: 2rem 1rem;

    &__logo img {
      max-width: 8rem
    }
  }

  .burger-btn {
    width: 20px
  }
}