.header__menu-mobile {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-color: #fff;
  padding: 0;
  transition: all .3s ease
}

.header__menu-mobile--active {
  padding: 2rem;
  width: 100%
}

.header__menu-top {
  display: flex;
  justify-content: space-between
}

.header__menu-logo {
  display: block;
  max-width: 15rem
}

.close-btn {
  width: 30px;
  cursor: pointer
}

.header__menu-items {
  padding: 1rem 0 2rem 28px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0
}

.header__menu-item a {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  text-decoration: none
}

.header__menu-social {
  padding-left: 28px;
  display: flex;
  flex-direction: column;
  gap: 1rem
}

.header__menu-icon {
  display: flex;
  gap: 1rem
}

.header__menu-icon a {
  font-size: 2rem
}

.header_menu-link {
  color: #000;
  text-decoration: none;
  font-size: 1.25rem
}

.header_menu-text {
  margin-top: 1rem;
  color: #000;
  font-size: 1.25rem
}