.get-gift {
  background-image: url(../../assets/images/getGift/bg-discount.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
  padding: 117px 0 0;
  position: relative;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin: 38px 0 10px;
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    text-wrap: wrap;
    text-transform: uppercase;
    color: #000;
    max-width: 600px;
  }

  &__timer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 88px;
    gap: 10px;
    background-color: #FF0000;
    border-radius: 33px;

    div {
      display: flex;
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      color: #FFFFFF;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align: center;
  }

  &__button {
    position: absolute;
    bottom: -50px;
    width: 415px;
    height: 72px;
    background-color: #FF0000;
    border: none;
    border-radius: 30px;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #FFFFFF;
    cursor: pointer;
    outline: transparent;

    &::after {
      content: '';
      position: absolute;
      bottom: 26px;
      right: -10px;
      width: 61.72px;
      height: 68.71px;
      background-image: url(../../assets/images/getGift/gift.svg);
      background-size: cover;
    }
  }

}


@media (max-width:920px) {
  .get-gift {
    background-position: right;
    height: 400px;
    position: relative;
    margin-top: 5rem;

    &__inner {
      align-items: flex-start;
      padding-left: 13px;
    }

    &__title {
      margin: 70px 0 10px;
      font-size: 32px;
    }

    &__timer {
      position: absolute;
      top: -52px;
      left: -23px;
      width: 450px;
    }

    &__button {
      bottom: 0;
    }
  }
}

@media (max-width:820px) {
  .get-gift {
    &__title {
      font-size: 28px;
    }

    &__text {
      font-size: 16px;
    }

    &__button {
      width: 360px;
    }
  }
}

@media (max-width:760px) {
  .get-gift {
    &__title {
      margin: 50px 0 10px;
      font-size: 24px;
      max-width: 360px;
      line-height: 20px;
      text-align: left;
    }

    &__text {
      font-size: 16px;
      max-width: 280px;
      text-align: left;
    }

    &__button {
      width: 300px;

      &::after {
        bottom: -47px;
        right: -100%;
        width: 120px;
        height: 140px;
      }
    }
  }
}

@media (max-width:630px) {
  .get-gift {
    &__title {
      font-size: 20px;
      max-width: 300px;
      line-height: 17px;
    }

    &__text {
      font-size: 16px;
      max-width: 260px;
      text-align: left;
    }

    &__button {
      width: 230px;
    }

    &__timer {
      position: absolute;
      top: -52px;
      left: -23px;
      width: 350px;
      height: 53px;

      div {
        font-size: 30px;
      }
    }
  }

}

@media (max-width:580px) {
  .get-gift {
    height: 260px;

    &__title {
      font-size: 18px;
      max-width: 230px;
      line-height: 17px;
      margin: 5px 0;
    }

    &__timer {
      width: 312px;

      div {
        font-size: 24px;
      }
    }

    &__text {
      font-size: 14px;
      max-width: 200px;
    }

    &__button {
      width: 190px;
      height: 43px;
      font-size: 20px;

      &::after {
        bottom: -22px;
        right: -160%;
        width: 84px;
        height: 84px;
      }
    }
  }
}



@media (max-width:500px) {
  .get-gift__button::after {
    right: -130%;
  }
}

@media (max-width:450px) {
  .get-gift {
    height: 240px;
    padding-top: 90px;

    &__button::after {
      right: -100%;
    }
  }
}

@media (max-width:400px) {
  .get-gift {
    height: 222px;
    padding-top: 80px;

    &__button::after {
      right: -80%;
    }

    &_title {
      font-size: 14px;
      max-width: 130px;
    }

    &__text {
      font-size: 10px;
      max-width: 193px;
      line-height: 12px;
    }
  }
}